.App {
  text-align: center;
}


.camPreview {
  background-color: black;
  height: 500px;
  border-radius: 15px;
  width: 500px;
  position: relative;
  overflow: hidden;
}

.toggleButtons {
  position: absolute;
  bottom: 15px;
}

.toggleButton {
  background-color: #fa3343;
  border-radius: 50%;
  padding: 10px;
}

.camOffText {
  position: absolute;
  color: white;
  top: 50%;
}

.big-buttons {
  border-radius: 50px !important;
  margin: 50px;
  min-width: 400px;
  max-width: 400px;
  color: white;
}



@media screen and (max-width: 850px) {
  .entries {
    width: 80%;
  }

  .camPreview {
    width: 400px;
    height: 400px;
  }
}

.camPreview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  height: 43px;
}

@media screen and (max-width: 1068px) {
  .pr_container {
    top: 20px !important;
    left: 20px !important;
  }
}